import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, Routes } from '@angular/router';

import { AuthGuardFn, AuthGuardNoOrgFn } from '@guards';

export const ROUTES: Routes = [
    {
        path: 'login',
        loadComponent: () => import('./login/login.component').then(mod => mod.LoginComponent)
    },
    {
        path: 'ui',
        canActivate: [AuthGuardNoOrgFn],
        loadChildren: () => import('./ui/ui.module').then(mod => mod.UiModule)
    },
    {
        path: 'guest',
        loadChildren: () => import('./guest/guest.module').then(mod => mod.GuestModule),
    },
    {
        path: 'orgselect/:target',
        canActivate: [AuthGuardFn()],
        loadChildren: () => import('./orgselect/orgselect.module').then(mod => mod.OrgselectModule)
    },

    {
        path: '**',
        redirectTo: '/ui'
    }
];

@Injectable()
export class CustomRouteReuseStrategy extends RouteReuseStrategy {
    public shouldDetach(_route: ActivatedRouteSnapshot): boolean { return false; }
    public store(_route: ActivatedRouteSnapshot, _detachedTree: DetachedRouteHandle): void {}
    public shouldAttach(_route: ActivatedRouteSnapshot): boolean { return false; }
    public retrieve(_route: ActivatedRouteSnapshot): DetachedRouteHandle | null { return null; }
    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return (future.routeConfig === curr.routeConfig) || (curr.data?.reuse && curr.data.reuse == future.data?.reuse);
    }
}
